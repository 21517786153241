import { useEffect, useState} from 'react';
import api from '../../services/api';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import "./style.css"
import AOS from 'aos';
import 'aos/dist/aos.css';

function Tecnologia(){
    const {id} = useParams();
    const [filmes, setFilmes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [description, setDescription] = useState({});

    //Toda vez que roda a aplica
    //consumindo API com axios
    useEffect(()=>{
        AOS.init();
        //rodar função de forma asincrona
        async function loadFilmes(){
            //await para ele esperar a busca na api e evitar erro
            //const response = await api.get("/search/movie"
            const response = await api.get(`/list/${id}`,{
                params: {
                    api_key: process.env.REACT_APP_API_KEY_TMDB,
                    language: "pt-BR"
                }
            })

            console.log(response)

            setFilmes(response.data.items)
            setDescription(response.data.description)
            setLoading(false)
        }

        loadFilmes();
    },[id])

    //controlador de load
    if(loading){
        return(
            <div class='loading'>
                <h2>Carregando filmes</h2>
            </div>
        )
    }
    return(
        <div className='container'>
        <div className='descricao' data-aos="fade-left" data-aos-duration = "900">
            <h4>
                {description}
            </h4>
        </div>
            
        <div class="lista-filmes" data-aos="fade-up" data-aos-duration = "900">
            
            {filmes.map((filme)=>{
                return(
                    <Link  to ={`/filme/${filme.id}` } >
                        <div key ={filme.id} className='card-filme'>
                            <img src= {`https://image.tmdb.org/t/p/original${filme.poster_path}`} alt={filme.title}></img>
                        </div>
                    </Link>
)})
}
    </div> 
    </div>  

    
    )
}

export default Tecnologia;