import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { FiGithub, FiLinkedin, FiMail, FiUser } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import LogoEducaFilmes from "../../imgs/LOGO.png";
import "./index.css";

const Footer = () => {
  
  return (
    <footer className="bg-dark text-light py-5 footer">
      <Container>
        <Row>
          <Col md={6} className="mb-3 mb-md-0">
            <div>
              <img src={LogoEducaFilmes} width="130px"></img>
              
            </div>
            
            <h5 className="mb-3">Sobre o SaberFlix</h5>
            <p>
              O SaberFlix é uma plataforma educacional de filmes que visa
              proporcionar uma experiência única de aprendizado aos estudantes.
              Nós selecionamos cuidadosamente os filmes que fazem parte da nossa
              biblioteca, buscando sempre conteúdos relevantes e educativos para
              complementar o ensino.
            </p>
          </Col>
          <Col md={3} className="mb-3 mb-md-0">
            <h5 className="mb-3">Informações do Desenvolvedor</h5>
            <ul className="list-unstyled">
              <li>
                <FiMail className="me-2" />
                <a href="mailto:victorhugofny@gmail.com">
                  victorhugofny@gmail.com
                </a>
              </li>
              <li>
                <FiLinkedin className="me-2" />
                <a target='_blank' href="https://www.linkedin.com/in/victor-hugo-santana/">
                  Linkedin
                </a>
              </li>
              <li>
              <FiUser className="me-2" />
                <a href="https://victorhugofny.vercel.app/" target='_blank'>
                  Portfólio
                </a>
              </li>
              <li>
              <FiGithub className="me-2" />
                <a href="https://github.com/VictorHugofny" target='_blank'>
                  Github
                </a>
              </li>
            </ul>
          </Col>
          
        </Row>
        <Row>
           <Col className="text-center">
          
            <p className="mb-0">
              Plataforma desenvolvida por <a href="https://victorhugofny.vercel.app/" target='_blank'>
                  @victorhugofny
                </a> em parceria com o IFBaiano <img  width="14px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Logotipo_IFET.svg/1200px-Logotipo_IFET.svg.png" className="logotipoIF"></img>
            </p>
            <p className="mb-0">
              © 2023 SaberFlix - Todos os direitos reservados
            </p>
           

          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
