import { useEffect, useState} from 'react';
import api from '../../services/api';
import {Link} from 'react-router-dom';
import './style.css'
import Carousel from 'react-bootstrap/Carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home(){
    const [filmes, setFilmes] = useState([]);
    const [filmesPrincipais, setFilmesPrincipais] = useState([]);
    const [loading, setLoading] = useState(true);

    //Toda vez que roda a aplica
    //consumindo API com axios
    useEffect(()=>{
        AOS.init();
        
        //rodar função de forma asincrona
        async function loadFilmes(){
            //await para ele esperar a busca na api e evitar erro
            //const response = await api.get("/search/movie"
            const response = await api.get('/list/8217764',{
                params: {
                    api_key: process.env.REACT_APP_API_KEY_TMDB,
                    language: "pt-BR"
                    // include_video:true,
                    // include_adult: false,
                    // page:1
                }
            })
            console.log(response.data.items)
            setFilmes(response.data.items)
            
        }
        

        async function loadFilmesPrincipais(){
            //await para ele esperar a busca na api e evitar erro
            //const response = await api.get("/search/movie"
            const response = await api.get('/list/8243399',{
                params: {
                    api_key: process.env.REACT_APP_API_KEY_TMDB,
                    language: "pt-BR"
                    // include_video:true,
                    // include_adult: false,
                    // page:1
                }
            })
            setFilmesPrincipais(response.data.items)
            setLoading(false)
        }

        loadFilmes();
        loadFilmesPrincipais();
        
    },[])

    //controlador de load
    if(loading){
        return(
            <div className='loading'>
                <h2>Carregando filmes</h2>
            </div>
        )
    }
    return(
        <div className='container'>
            <div data-aos="flip-up" data-aos-duration = "800">
            <Carousel>
                    {filmesPrincipais.map((filme)=>{
                        return(
                            <Carousel.Item key ={filme.id} className="banner-filmes">
                                <Link  to ={`/filme/${filme.id}` } >
                                <img  className="d-block w-100" id ="slider"  src= {`https://image.tmdb.org/t/p/original${filme.backdrop_path}`} alt={filme.title}></img>
                                </Link>
                                <Carousel.Caption>
                                <h3>{filme.title}</h3>
                                </Carousel.Caption>
                            </Carousel.Item> 
                    )})}
            </Carousel>
            </div>
            
            
            <div data-aos="fade-left" data-aos-duration = "900">
                <p className='descricaoHome'>
                Bem-vindo ao SaberFlix! Somos uma plataforma sobre filmes educativos, com conteúdo selecionado especialmente para apoiar a aprendizagem e o desenvolvimento de estudantes, educadores e todos os interessados em conhecimento e cultura.
                </p>   
            </div>
                
        
        <div className="lista-filmes" data-aos="fade-up" data-aos-duration = "900">
            {filmes.map((filme)=>{
                return(
                    <Link key ={filme.id} to ={`/filme/${filme.id}` } >
                    <div key ={filme.id} className='card-filme'>
                        <img src= {`https://image.tmdb.org/t/p/original${filme.poster_path}`} alt={filme.title}></img>
                    </div>
                    </Link>
    )})
}
    </div> 
    </div>  



    
    )
}

export default Home;