import logo from '../../imgs/LOGO.png'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import './style.css'

function Header(){
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return(
      <>
        <Navbar key={"xl"} bg="dark" variant="dark" expand={"lg"} className="mb-3">
          <Container fluid>
          <Navbar.Brand href="/"> 
          <div className='LogoTitulo'>
          <img
              width={120}
              height={60}
              src={logo}
              
            />
          </div>
          </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"xl"}`} />
            
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${"xl"}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${"xl"}`}
              placement="end"  
            >
              <Offcanvas.Header closeButton >
                <Offcanvas.Title  id={`offcanvasNavbarLabel-expand-${"xl"}`}>
                  <Navbar.Brand href="/"> <img
                    width={120}
                    height={60}
                    src={logo}/>
                  </Navbar.Brand>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body >
                <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav className="me-auto">
                  <Nav.Link href="/">Página inicial</Nav.Link>                  

                  <NavDropdown
                    title="Categorias"
                    id={`offcanvasNavbarDropdown-expand-${"x2"}`}
                  >
                    <NavDropdown.Item className='mais' href="/categoria/8248760">
                      Educação e Pedagogia
                    </NavDropdown.Item>
                    <NavDropdown.Item className='mais' href="/categoria/8248754">
                      Filosofia
                    </NavDropdown.Item>
                    <NavDropdown.Item className='mais' href="/categoria/8248757">
                      Física
                    </NavDropdown.Item>
                    <NavDropdown.Item className='mais' href="/categoria/8218102">
                      História
                    </NavDropdown.Item>
                    <NavDropdown.Item className='mais' href="/categoria/8218104">
                      Inclusão Social
                    </NavDropdown.Item>
                    <NavDropdown.Item className='mais' href="/categoria/8218106">
                      Infantil
                    </NavDropdown.Item>
                    
                    <NavDropdown.Item className='mais' href="/categoria/8249256">
                      Matemática
                    </NavDropdown.Item>
                    <NavDropdown.Item className='mais' href="/categoria/8217766">
                      Tecnologia e Inovação
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                 
                </Nav>
                <Form className="d-flex">
                 
                <Button variant="dark" onClick={handleShow}>
                  <strong>Sugerir Filme</strong> 
                </Button>

                <Modal
                  size="lg"
                  show={show}
                  onHide={handleClose}
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe2SAPu0Tg4S19FdH5RSObiWHFaK4eGzw8pDx9LvAI45ohSng/viewform?embedded=true" width="1050" height="800" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>
                  </Modal.Header>
                
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Fechar
                    </Button>
                  </Modal.Footer>
                </Modal>
                  
                  <p>  </p>
                  <Nav.Link href="/favoritos">
                    <button type="button" class="btn btn-outline-light">Favoritos</button>
                    {/* <Button variant="secondary"><strong>Favoritos</strong></Button> */}
                  </Nav.Link>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

    </>
      
        
       
    )
}


export default Header;