import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './views/Home';
import Filme from './views/Filme';
import Header from './components/Header';
import Erro from './views/Erro';
import Favoritos from './views/Favoritos';
import Categoria from './views/Categoria';
import Footer from './components/Footer';

function RoutesAPP(){
      
    return(
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/categoria/:id" element={<Categoria/>}/>
                <Route path="/filme/:id" element={<Filme/>}/>
                <Route path="/favoritos" element={<Favoritos/>}/>
                <Route path ="*" element={<Erro/>}/>
            </Routes>
           <Footer/>
            
        </BrowserRouter>
    )}

export default RoutesAPP;