import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import { toast } from 'react-toastify';
import { useEffect, useState } from "react";
import Comment from "../../services/comment";
import Modal from 'react-bootstrap/Modal';
import "./style.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import badWord from "../../badwords.json";
let api = process.env.REACT_APP_API_COMMENT_SABERFLIX;


const EnviarComment = ({ id_filme }) => {
  const badWords = badWord.bad_word

  const [comments, setComments] = useState([]);
  const [discursao, setDiscursao] = useState('');
  const [nota, setNota] = useState('');
  const [nome, setNome] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const comment = { id_filme, discursao, nota, nome, like: 0, deslike: 0 };

    if(filterText(comment.discursao) == true && filterText(comment.nome) == true){
    axios.post(api + '/comentarios', comment, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        console.log(response)
        if (!response.data) {
          throw new Error('Erro ao enviar o comentário.');
        }
        toast.success('Comentário enviado com sucesso!');
        setDiscursao('');
        setNota('');
        setNome('');
        atualizarComentarios()
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error);
        console.error('Erro:', error);
        alert('Erro ao enviar o comentário. Tente novamente mais tarde.');
      });

    handleClose();
  }
  else{
    toast.error('O seu comentário contém palavras inadequadas e não é permitido. Por favor, revise o tipo de comentário que deseja fazer, evitando o uso de linguagem ofensiva ou inapropriada. Agradecemos a sua compreensão e colaboração.');
  }
  };

  function atualizarComentarios() {
    axios
      .get(api + `/comentarios/${id_filme}`)
      .then((response) => {
        setComments(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function filterText(text) {
    // Converter o texto para minúsculas para evitar problemas de capitalização
    const lowerText = text.toLowerCase();

    for (let i = 0; i < badWords.length; i++) {
      if (lowerText.includes(badWords[i])) {
        console.log(badWords[i])
        return false; // Contém uma palavra proibida
      }
    }

    return true; // Não contém palavras proibidas
  }

  useEffect(() => {
    AOS.init();
    atualizarComentarios();
  }, []);

  return (
    <div className='View-comentarios'>

      <Modal show={show} onHide={handleClose} className='modal-comentario'>
        <Modal.Header closeButton>
          <Modal.Title><button type="button" class="btn btn-outline-light">Light</button>
Adicionar comentário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className='formulario-comentario'>
            <Col>
              <h5>Seu nome</h5>
              <Form.Control
                type="text"
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
              />
            </Col>
            <Col>
              <h5>Nota do Filme</h5>
              <Form.Control
                type="number"
                id="nota"
                value={nota}
                onChange={(e) => setNota(e.target.value)}
                required
                min="0"
                max="10"
              />
            </Col>
            <Col>
              <h5>Comentário</h5>
              <Form.Control
                as="textarea"
                rows={6}
                id="discursao"
                value={discursao}
                onChange={(e) => setDiscursao(e.target.value)}
                required
              />
            </Col>
            <br />
            <Button variant="primary" type="submit">
              ENVIAR
            </Button>
          </Form>
        </Modal.Body>
      </Modal>



      <div>
        <div className='Topo-comentarios'>
          {(() => {
            if (comments.length > 0) {
              return <h3>Avaliações</h3>;
            }
          })()}
          <button type="button" class="btn btn-primary btn-lg" onClick={handleShow} >
            Adicionar um comentario
          </button>
        </div>
        <div data-aos="fade-up" data-aos-duration = "900">
        {comments.map((comment) => (

          <Comment
            key={comment._id}
            id={comment._id}
            nome={comment.nome}
            discursao={comment.discursao}
            nota={comment.nota}
            like={comment.like}
            deslike={comment.deslike}
          />
        ))}
        </div>
        
      </div>
    </div>
  );
};

export default EnviarComment;






