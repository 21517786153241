import React, { useState, useEffect } from "react";
import axios from "axios";
import likeImg from "../../src/imgs/like.png";
import dislikeImg from "../../src/imgs/deslike.png";
import { toast } from 'react-toastify';
import './style.css';

let api = process.env.REACT_APP_API_COMMENT_SABERFLIX;

const Comment = ({ id, nome, discursao, like, deslike, nota }) => {
  const [numLikes, setNumLikes] = useState(like);
  const [numDislikes, setNumDislikes] = useState(deslike);
  const [userLiked, setUserLiked] = useState(false);
  const [userDisliked, setUserDisliked] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.likes && user.likes.includes(id)) {
      setUserLiked(true);
    }
    if (user && user.dislikes && user.dislikes.includes(id)) {
      setUserDisliked(true);
    }
  }, [id]);

  const handleLike = () => {
    if (userLiked) {
      toast.warning("Você já curtiu este comentário");
      return;
    }

    const user = JSON.parse(localStorage.getItem("user")) || { likes: [], dislikes : [] };
    user.likes.push(id);
    localStorage.setItem("user", JSON.stringify(user));

    axios
      .put(api + `/comentarios/${id}`, { like: numLikes + 1 })
      .then(() => {
        setNumLikes(numLikes + 1);
        setUserLiked(true);
        toast.success("Like adicionado");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDislike = () => {
    console.log(numDislikes)
    
    if (userDisliked) {
      toast.warning("Você já descurtiu este comentário");
      return;
    }

    const user = JSON.parse(localStorage.getItem("user")) || { dislikes: [], likes: [] };
    user.dislikes.push(id);
    localStorage.setItem("user", JSON.stringify(user));

    axios
      .put(api + `/comentarios/${id}`, { deslike: numDislikes + 1 })
      .then(() => {
        setNumDislikes(numDislikes + 1);
        setUserDisliked(true);
        toast.success("Dislike adicionado");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className='comentarios'>
      <div className="comentarioDiscur">
        <strong>Comentário:</strong> 
        <p style={{ wordWrap: 'break-word' }}>{discursao}</p>
      </div>

      <div className="nome-nota">
        <p><strong>Nome:</strong> {nome}</p>
        <p><strong>Nota:</strong> {nota}</p>
      </div>

      <div className="avaliacoes">
        <button onClick={handleLike} className="like">
          <p class ="numLikes">{numLikes}</p>
          <img className="botaoLike" src={likeImg} alt="Like"></img>
        </button>
        <button onClick={handleDislike} className="deslike">
          <p class ="numDislikes">{numDislikes}</p>
          <img className="botaoDislike" src={dislikeImg} alt="Dislike"></img>
        </button>
      </div>
    </div>
  );
};

export default Comment;