import {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import './style.css'
import Comments from '../../components/comentarios';
import listaStreaming from './listaStreaming.json';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Filme(props){
    const {id} = useParams();
    const navigation = useNavigate();
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [filme, setFilme] = useState({});
    const [loading, setLoading] = useState(true);
    const [comentarios, setComentarios] = useState([]);
    const [servicesStream, setServices] = useState([]);

    useEffect(()=>{
        AOS.init();

        //esperar requisição
        async function loadFilme(){

            await api.get(`/movie/${id}`,{
                params:{
                    api_key: process.env.REACT_APP_API_KEY_TMDB,
                    language: "pt-BR"
                }
            })
            .then((response)=>{
                setFilme(response.data);
                setLoading(false);
                
            })
            .catch((erro)=>{
                navigation("/",{ replace:true})
                return;
            });
        }

        async function loadStreaming(){
            await api.get(`/movie/${id}/watch/providers`,{
                params:{
                    api_key: process.env.REACT_APP_API_KEY_TMDB,
                }
            })
            .then((response)=>{
                console.log(response.data.results.BR.flatrate)
                if(response.data.results.BR.flatrate){
                    setServices(response.data.results.BR.flatrate);
                }else{
                    setServices([]);
                }
                
            })
            .catch((erro)=>{
                return;
            });
        }

        loadFilme();
        loadStreaming();
        //loadComentarios();
    },[navigation, id, comentarios])


    function salvarFilme(){
        //pega lista de filmes
        const minhaLista = localStorage.getItem("@saberflix");

        //tentar buscar e se não existir iniciar vazio
        let filmesSalvos = JSON.parse(minhaLista) || [];
        
        //verifica se ja existe esse filme na lista
        const hasFilme = filmesSalvos.some((filmesSalvos)=> filmesSalvos.id === filme.id)
        
        if(hasFilme){
            toast.warn("Filme ja existe");
            return;
        }

        filmesSalvos.push(filme);
        //cria lista de filmes
        localStorage.setItem("@saberflix",JSON.stringify(filmesSalvos));
        toast.success("Filme salvo com sucesso");
    }


    if(loading){
        return(
            <div>
                <h1>Carregando filme</h1>
            </div>
        )
    }
    return(
        
            <div className='conteudo-filme'>
            <div className='filme-info' >
                <div data-aos="fade-up" data-aos-duration = "900">
                <br></br>
                <h1>{filme.title}</h1>
                <img className='capaFilme' src= {`https://image.tmdb.org/t/p/original${filme.backdrop_path}` } alt={filme.title}></img>
                
                
                
                <br></br>
                <span><strong>Sinopse</strong>: {filme.overview}</span>
                {(() => {
                if (servicesStream.length > 0) {
                    return <h3>Disponível em:</h3>;
                }
                })()}

                <div className='servicos'>
                    {servicesStream.map((services)=>{

                        let linkStreaming;
                        listaStreaming.forEach(servico => {
                            if (servico.nome === services.provider_name) {
                                linkStreaming = servico.link
                            }
                          });

                        return(
                            <a key ={services.provider_id} href={linkStreaming} target="_blank"> 
                            <img  key ={services.provider_id} src = {`https://image.tmdb.org/t/p/original${services.logo_path}` } alt={filme.title} className = "servicosStream"></img>
                            </a>
                    )})}
                </div>    
                <div className='buttons'>

                <button onClick={salvarFilme} class="btn btn-outline-light btn-lg">
                    Salvar
                </button>{' '}
                    <a target='blank' href={`https://www.youtube.com/results?search_query=${filme.title} filme`}>
                    <button onClick={salvarFilme} class="btn btn-outline-light btn-lg">
                        Conteúdo no YouTube
                    </button>{' '}
                    </a>
                </div>
                                  
                </div>            

        <br></br>
        
        <div><Comments id_filme={id}/></div>
        
    </div>
    </div>
)}

export default Filme;

