import RoutesAPP from './routes';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  'bootstrap/dist/css/bootstrap.min.css' ;
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className="App">
      <ToastContainer autoClose={3000}/>
      <Analytics />
      <RoutesAPP/>
    </div>
  );
}

export default App;
